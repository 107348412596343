<template>
  <!-- 行驶数据 -->
  <div>
    <div :class="$style.title">
      <svg :class="$style.icon" width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="0%" y1="0%" y2="100%" id="t_a">
            <stop stop-color="#2DC9EB" offset="0%" />
            <stop stop-color="#14D2B8" offset="100%" />
          </linearGradient>
          <linearGradient x1="97.04%" y1="50%" x2="0%" y2="50%" id="t_b">
            <stop stop-color="#FFF" offset="0%" />
            <stop stop-color="#ECE9E9" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <circle fill="url(#t_a)" cx="17" cy="17" r="17" />
          <g opacity=".598" transform="rotate(-90 19.55 7.225)">
            <path
              d="M14.968 0c.35 0 .655.197.809.486h.773a3 3 0 0 1 3 3V6.25a3 3 0 0 1-3 3h-.773a.916.916 0 0 1-1.618 0H3a3 3 0 0 1-3-3V3.487a3 3 0 0 1 3-3L14.159.486A.916.916 0 0 1 14.968 0z"
              fill="url(#t_b)" />
            <rect stroke="#979797" stroke-width=".25" fill="#CBCBCB" opacity=".253" x=".125" y="1.516" width="16.507"
              height="6.705" rx="3" />
            <path
              d="M15.062 1.39h2.604a1 1 0 0 1 .993.88c.129 1.051.193 2 .193 2.848 0 .79-.056 1.58-.168 2.368a1 1 0 0 1-.99.86h-2.626a1 1 0 0 1-.997-1.079c.06-.753.089-1.528.089-2.325 0-.825-.032-1.65-.095-2.475a1 1 0 0 1 .997-1.076z"
              fill="#00F6E5" />
          </g>
        </g>
      </svg>
      <span>在线车辆</span>
      <strong>{{ vehicleTotalCountShow | formatNumber }}</strong>
    </div>
    <div :class="$style.news">
      <svg :class="$style.icon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g fill="#00F6E5" fill-rule="evenodd">
          <path
            d="M8.033 13.767c-.466 0-.966-.167-1.4-.467l-1.9-1.367a3.3 3.3 0 0 0-1.3-.6l-.566-.133c-.034 0-.034 0-.067-.033-.767-.334-1.3-1.167-1.3-2.1v-2.2c0-.934.5-1.734 1.3-2.1.033 0 .033-.034.067-.034l.766-.2c.3-.066.6-.2.867-.4l2.133-1.466c.967-.7 2.3-.567 3.1.266.367.4.567.934.567 1.434v7.2c0 .733-.4 1.433-1.067 1.866a2.176 2.176 0 0 1-1.2.334zm-.8-1.267c.467.333 1 .367 1.434.1.366-.233.6-.633.6-1.033V4.4c0-.367-.167-.633-.3-.767-.434-.466-1.2-.533-1.767-.133L5.1 5c-.367.267-.767.433-1.2.567l-.733.2c-.4.2-.667.666-.667 1.166v2.2c0 .5.267.967.667 1.167l.533.133c.567.167 1.133.4 1.633.767l1.9 1.3zM13.033 12c-.033 0-.1 0-.133-.033-.267-.067-.4-.367-.333-.634l.366-1.266a6.817 6.817 0 0 0 0-3.834l-.366-1.266a.528.528 0 0 1 .333-.634.528.528 0 0 1 .633.334l.367 1.266a7.722 7.722 0 0 1 0 4.434l-.367 1.266a.5.5 0 0 1-.5.367z" />
        </g>
      </svg>
      <div :class="$style.marqueeWrap">
        <transition-group name="marquee-list" tag="ul" :class='$style.marquee'>
          <li v-for="item in news" :key="item.id" :class='$style.item'>{{ item.txt }}</li>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.title {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  font-size: .7rem;
  color: #8E94CC;

  .icon {
    margin-right: .6rem;
    width: 1.4rem;
    height: 1.4rem;
  }

  strong {
    margin-left: 0.6rem;
    font-size: 1.2rem;
    color: #fff;
  }
}

.news {
  display: flex;
  padding-bottom: .5rem;
  width: 100%;

  .icon {
    margin-right: .4rem;
    width: .8rem;
    height: .8rem;
  }

  .marqueeWrap {
    flex: 1;
    height: .8rem;
    overflow: hidden;
  }

  .marquee {
    flex: 1;
    height: 1.6rem;
    line-height: .8rem;
    color: #8E94CC;
    overflow: hidden;
    transform: translateY(-0.8rem);

    .item {
      display: flex;
      height: .8rem;
      overflow: hidden;
      justify-content: space-between;
      transition: transform 1s;
    }

    :global(.marquee-list-move):last-child {
      opacity: 0;
    }
  }
}
</style>

<script>
import { TweenLite } from 'gsap';

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false,
    },
    vData: {
      type: Object,
      default: () => ({
        vehicleTotalCount: 0,
        news: [],
      }),
    },
  },
  data () {
    const { news } = this.vData;
    const last = news.pop();

    return {
      interval: undefined,
      vehicleTotalCountShow: 0,
      news: [
        last,
        ...news,
      ]
    };
  },
  watch: {
    vData () {
      const { news: oldNews } = this;
      const { news } = this.vData;

      const _oNews = oldNews.filter((item) => {
        return news.find((nItem) => nItem.id === item.id);
      });
      const _nNews = news.filter((item) => {
        return !oldNews.find((oItem) => oItem.id === item.id);
      })

      this.news = [
        ..._oNews,
        ..._nNews,
      ];
      this.animed();
    }
  },
  mounted () {
    this.animed();

    this.interval = setInterval(() => {
      const { news } = this;
      const first = news.shift();

      this.news = [
        ...news,
        first
      ];
    }, 3000)
  },
  beforeDestroy () {
    this.interval && clearInterval(this.interval);
  },
  methods: {
    animed () {
      // const { vehicleTotalCount } = this.vData;

      TweenLite.to(this.$data, 1, {
        vehicleTotalCountShow: 162,
      })
    },
    getRandomNum (Min, Max) {
      var Range = Max - Min;
      var Rand = Math.random();
      return (Min + Math.round(Rand * Range));
    }
  },
};
</script>